<template>
  <div style="height: 100%;background: #fff;">
    <div class="biao_ti">
      <van-icon @click="back" class="fanhui" name="arrow-left" />
      <span style="vertical-align: middle;">自助选车</span>
    </div>
    <van-search show-action class="shousuo" v-model="brandName" placeholder="请输入搜索关键词">
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <div style="padding:108px 0px 0px 0px;">
      <div class="remen">热门品牌</div>
      <div class="remen_a">
        <div class="remen_b" v-for="(item,idx) in remen" :key="'r'+idx" @click="chelist(item)">
          <img class="r_img" :src="item.ppinpaiLogoUrl" alt />
          <span>{{item.ppinpai}}</span>
        </div>
      </div>
      <van-index-bar>
        <van-index-anchor v-for="(item,idx) in list" :key="'i'+idx" :index="idx">
          <div class="indexWord">{{idx}}</div>
          <div v-for="(items,index) in item" :key="index" class="p_list" @click="chelist(items)">
            <img class="pinpai_img" :src="items.ppinpaiLogoUrl" alt />
            <span class="pinpai_txt">{{items.ppinpai}}</span>
          </div>
        </van-index-anchor>
      </van-index-bar>
    </div>
    <van-popup v-model="show" position="right" :style="{ height: '100%',width: '80%' }">
      <van-search show-action class="xinshou" v-model="seriesName" placeholder="请输入搜索关键词">
        <template #action>
          <div @click="onSearch2">搜索</div>
        </template>
      </van-search>
      <div class="m_lis">
        <img class="pinpai_img" :src="imgs" alt />
        <span class="imname">{{imgname}}</span>
      </div>
      <div class="huadong">
        <div
          v-for="(item,ind) in carlist"
          :key="ind"
          class="chexi"
          @click="baocun(item)"
        >{{item.seriesName}}</div>
        <div style="height:100px"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import userModel from "../../api/user";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  // mixins: [preventBack], //注入
  data() {
    return {
      loading: false,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      show: false,
      addForm: {},
      brandName: "",
      remen: [
        {
          id: 256,
          pgengxinshijian: "201903",
          ppinpai: "大众",
          ppinpaiId: "1",
          ppinpaiLogoUrl:
            "https://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/Logo/1.jpg",
          pshouzimu: "D"
        },
        {
          id: 297,
          pgengxinshijian: "201903",
          ppinpai: "本田",
          ppinpaiId: "14",
          ppinpaiLogoUrl:
            "https://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/Logo/14.jpg",
          pshouzimu: "B"
        },
        {
          id: 236,
          pgengxinshijian: "201903",
          ppinpai: "丰田",
          ppinpaiId: "3",
          ppinpaiLogoUrl:
            "https://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/Logo/3.jpg",
          pshouzimu: "F"
        },
        {
          id: 295,
          pgengxinshijian: "201903",
          ppinpai: "别克",
          ppinpaiId: "38",
          ppinpaiLogoUrl:
            "https://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/Logo/38.jpg",
          pshouzimu: "B"
        },
        {
          id: 3,
          pgengxinshijian: "201903",
          ppinpai: "福特",
          ppinpaiId: "8",
          ppinpaiLogoUrl:
            "https://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/Logo/8.jpg",
          pshouzimu: "F"
        },
        {
          id: 87,
          pgengxinshijian: "201903",
          ppinpai: "日产",
          ppinpaiId: "63",
          ppinpaiLogoUrl:
            "https://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/Logo/63.jpg",
          pshouzimu: "R"
        },
        {
          id: 30,
          pgengxinshijian: "201903",
          ppinpai: "现代",
          ppinpaiId: "12",
          ppinpaiLogoUrl:
            "https://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/Logo/12.jpg",
          pshouzimu: "X"
        },
        {
          id: 1,
          pgengxinshijian: "201903",
          ppinpai: "奥迪",
          ppinpaiId: "33",
          ppinpaiLogoUrl:
            "https://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/Logo/33.jpg",
          pshouzimu: "A"
        },
        {
          id: 29,
          pgengxinshijian: "201903",
          ppinpai: "雪佛兰",
          ppinpaiId: "71",
          ppinpaiLogoUrl:
            "https://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/Logo/71.jpg",
          pshouzimu: "X"
        },
        {
          id: 296,
          pgengxinshijian: "201903",
          ppinpai: "宝马",
          ppinpaiId: "15",
          ppinpaiLogoUrl:
            "https://vehicle-repairs.oss-cn-hangzhou.aliyuncs.com/Logo/15.jpg",
          pshouzimu: "B"
        }
      ],

      value: "",
      brandId: "",
      list: [],
      carlist: [],
      imgs: "",
      seriesName: "",
      imgname: ""
    };
  },

  methods: {
    //保存车型
    baocun(item) {
      // console.log(item);
      var _this = this;
      var data = {
        carBrand: item.brandName,
        carModel: item.seriesName,
        carBrandLogo:this.imgs,
      };
      console.log(data);
      sessionStorage.setItem("che", JSON.stringify(data));
      _this.$router.go(-1);
      // _this.$toast("添加成功");
      // setTimeout(() => {
      //   _this.$router.go(-1);
      // }, 1000);
    },
    //搜索
    onSearch() {
      this.onLoad();
    },
    //搜索
    onSearch2() {
      const data = {
        brandId: this.brandId,
        seriesName: this.seriesName
        // status: 2
      };
      userModel
        .findList(data)
        .then(e => {
          this.carlist = e.data;
        })
        .catch(() => loading.clear());
    },
    chelist(item) {
      console.log(item);
      this.seriesName = "";
      this.imgs = item.ppinpaiLogoUrl;
      this.imgname = item.ppinpai;
      this.brandId = item.ppinpaiId;
      const data = {
        brandId: item.ppinpaiId,
        seriesName: this.seriesName
        // status: 2
      };
      userModel
        .findList(data)
        .then(e => {
          this.carlist = e.data;
          console.log(e.data);
        })
        .catch(() => loading.clear());
      this.show = true;
    },
    onLoad() {
      const data = {
        brandName: this.brandName
        // status: 2
      };

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      userModel
        .chepinpailist(data)
        .then(e => {
          var arr = e.data;
          var hash = [];
          for (var i = 0; i < arr.length; i++) {
            if (hash.indexOf(arr[i].pshouzimu) == -1) {
              hash.push(arr[i].pshouzimu);
            }
          }
          let firstName = {};
          hash.map((c, i) => {
            firstName[c] = [];
            arr.map((d, i) => {
              if (c == d.pshouzimu) {
                firstName[c].push(d);
              }
            });
          });
          console.log(firstName);
          this.list = firstName;
          loading.clear();
        })
        .catch(() => loading.clear());
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    this.onLoad();
  }
};
</script>
<style>
.van-index-anchor {
  padding: 0;
}
</style>
<style lang="less" scoped>
.xinshou {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
.huadong {
  max-height: 100%;
  overflow-y: auto;
}
.van-popup {
  overflow: hidden;
}
.remen {
  font-size: 13px;
  font-weight: bold;
  background: #f7f8fa;
  padding: 0 12px;
  line-height: 35px;
}
.shousuo {
  position: fixed;
  top: 46px;
  left: 0;
  width: 100%;
  z-index: 99;
}
.pinpai_img {
  width: 35px;
  height: 35px;
  vertical-align: middle;
}
.pinpai_txt {
  vertical-align: middle;
  display: inline-block;
  width: 85%;
  margin-left: 10px;
  border-bottom: 1px solid #f5f5f5;
}
.van-index-anchor--sticky {
  top: 101px;
}
.chexi {
  font-size: 14px;
  line-height: 40px;
  border-bottom: 1px solid #f5f5f5;
  margin-left: 12px;
  color: #666;
}
.chexi:nth-child(1) {
  // margin-top: 45px;
}
.m_lis {
  margin-top: 45px;

  height: 45px;
  line-height: 41px;
  padding: 0 12px;
  border-bottom: 4px solid #f5f5f5;
  z-index: 99;
  background: #fff;
}
.imname {
  font-size: 16px;
  margin-left: 10px;
}

.indexWord {
  background: #f7f8fa;
  width: 100%;
  padding: 0 12px;
  line-height: 25px;
  color: #000;
}
.p_list {
  padding: 0 12px;
  height: 45px;
  line-height: 45px;
  color: #000;
}
.biao_ti {
  height: 46px;
  background: #fff;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  line-height: 46px;
  text-align: center;
  color: #000;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
}

.fanhui {
  line-height: 46px;
  padding: 0 10px;
  position: absolute;
  left: 0;
  top: 0;
}
.remen_a {
  padding: 10px 10px;
  background: #fff;
  overflow: hidden;
}
.r_img {
  width: 35px;
  height: 35px;
  display: block;
  margin: auto;
}
.remen_b {
  display: inline-block;

  font-size: 13px;
  text-align: center;
  width: 20%;
}
.remen_b:nth-of-type(n + 6) {
  margin-top: 10px;
}
</style>
<style>
.van-index-anchor {
  padding: 0;
}
</style>